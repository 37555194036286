/*--------------------------------------------------------
/
/    [RE]noun Creative WSK Page Section Builder
/     Version: 2.0.1
/     File: Custom functions for Page Sections
/
/-------------------------------------------------------*/

jQuery(document).ready(function($){

/*---------------------------------------------
/
/  	         SVG Fallback Support
/
/_____________________________________________*/

	//test for svg support
	function svgasimg() {
	  return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
	}
	//Swap if necessary
	if(!svgasimg()) {
	    $('img[src*="svg"]').attr('src', function () {
	    return $(this).attr('src').replace('.svg', '.png');
	  });
	}


/*---------------------------------------------
/
/  	    Function for Slider Media Items
/
/_____________________________________________*/

	$sliders = $('.media-item .slider-container');

	/*$recentPosts = $('.recent-posts-container');

	if ($recentPosts.length > 0) {
		$recentPosts.on('setPosition', debounce(function(){
			$slides = $('.recent-posts-container .slick-slide');
			$slides.css('height','');

			// Get an array of all element heights
			var elementHeights = $slides.map(function() {
				return $(this).height();
			}).get();

			// Math.max takes a variable number of arguments
			// `apply` is equivalent to passing each height as an argument
			var maxHeight = Math.max.apply(null, elementHeights);

			// Set each height to the max height
			$slides.height(maxHeight);
			
		},500));
	}*/
	

	//if there are sliders on this page, set each up based on specified theme
	if ($sliders) {
	  var sliders = {
	    init: function() {
	      this.cacheDom();
	      this.setupSliders();
	    },
	    cacheDom: function() {
	     
	    },
	    setupSliders: function() {
	      $sliders.each(function() {
	        //sliderName = $(this).attr('data-slider'),
	        sliderType = $(this).attr('data-type'),
	        $(this).slick(sliders.getTypeOptions(sliderType));
	      });
	    },
	    getTypeOptions: function(type) {
	      if (type == 'testimonial') {
	        var options = {
	              dots: true,
	              infinite: true,
	              slidesToShow: 1,
	              fade: false,
	              adaptiveHeight: true,
	              swipeToSlide: true,
	              slickFilter: '.slide',
	        }
	        return options;

	      } else if (type == 'content-block') {
	        var options = {
	              dots: false,
	              infinite: true,
	              slidesToShow: 3,
	              fade: false,
	              adaptiveHeight: true,
	              swipeToSlide: true,
	              slickFilter: '.slide',
	              responsive: [
	              {
	                breakpoint: 701,
	                settings: "unslick",
	              },
	              {
	                breakpoint: 700,
	                settings: {
	                  slidesToShow: 1,
	                  autoplay: false,
	                  dots: true,
	                  autoplay: false,
	                }
	              }]
	          	}
	        } else if (type == 'posts') {
	        var options = {
	        	slidesToShow: 3,
				slidesToScroll: 1,
				fade: false,
				adaptiveHeight: false,
				swipeToSlide: true,
				dots: false,
				//slickFilter: '',
				responsive: [
/*					{
						breakpoint: 1023,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							infinite: true,
							dots: false
						}
					},*/
					{
						breakpoint: 1023,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 639,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
	        }
	        return options;

	      } else {

	        return false;
	      }
	    },
	  }; //end sliders object

	  sliders.init();
	};

/*---------------------------------------------
/
/    		 	Functions
/
/_____________________________________________*/

	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
	var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

}); //end of ready

/*---------------------------------------------
/
/			  ACF Google Maps Function
/
/_____________________________________________*/

$map = jQuery('.google-map');

if ($map.length > 0) {

	jQuery.getScript('https://maps.googleapis.com/maps/api/js?key='+page_section_data.google_api_key, function(){


		(function($) {



			/*
			*  new_map
			*
			*  This function will render a Google Map onto the selected jQuery element
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	4.3.0
			*
			*  @param	$el (jQuery element)
			*  @return	n/a
			*/

			function new_map( $el ) {
				
				// var
				var $markers = $el.find('.marker');
				
				
				// vars
				var args = {
					zoom		: 16,
					center		: new google.maps.LatLng(0, 0),
					mapTypeId	: google.maps.MapTypeId.ROADMAP
				};
				
				
				// create map	        	
				var map = new google.maps.Map( $el[0], args);
				
				
				// add a markers reference
				map.markers = [];
				
				
				// add markers
				$markers.each(function(){
					
			    	add_marker( $(this), map );
					
				});
				
				
				// center map
				center_map( map );
				
				
				// return
				return map;
				
			}

			/*
			*  add_marker
			*
			*  This function will add a marker to the selected Google Map
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	4.3.0
			*
			*  @param	$marker (jQuery element)
			*  @param	map (Google Map object)
			*  @return	n/a
			*/

			function add_marker( $marker, map ) {

				// var
				var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

				// create marker
				var marker = new google.maps.Marker({
					position	: latlng,
					map			: map
				});

				// add to array
				map.markers.push( marker );

				// if marker contains HTML, add it to an infoWindow
				if( $marker.html() )
				{
					// create info window
					var infowindow = new google.maps.InfoWindow({
						content		: $marker.html()
					});

					// show info window when marker is clicked
					google.maps.event.addListener(marker, 'click', function() {

						infowindow.open( map, marker );

					});
				}

			}

			/*
			*  center_map
			*
			*  This function will center the map, showing all markers attached to this map
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	4.3.0
			*
			*  @param	map (Google Map object)
			*  @return	n/a
			*/

			function center_map( map ) {

				// vars
				var bounds = new google.maps.LatLngBounds();

				// loop through all markers and create bounds
				$.each( map.markers, function( i, marker ){

					var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

					bounds.extend( latlng );

				});

				// only 1 marker?
				if( map.markers.length == 1 )
				{
					// set center of map
				    map.setCenter( bounds.getCenter() );
				    map.setZoom( 16 );
				}
				else
				{
					// fit to bounds
					map.fitBounds( bounds );
				}

			}

			/*
			*  document ready
			*
			*  This function will render each map when the document is ready (page has loaded)
			*
			*  @type	function
			*  @date	8/11/2013
			*  @since	5.0.0
			*
			*  @param	n/a
			*  @return	n/a
			*/
			// global var
			var map = null;

			$(document).ready(function(){

				$('.acf-map').each(function(){

					// create map
					map = new_map( $(this) );

				});

			});

		})(jQuery);


	});


};